import request from "@/utils/request";

export function loginByCode(code) {
  return request({
    url: 'login/code',
    method: 'post',
    data: {
      code,
    }
  });
}
